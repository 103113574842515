import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import TemplateHeader from "../../components/TemplatePages/TemplateHeader/TemplateHeader";
import TitleSmBgAndDesc from "../../components/TemplatePages/TitleSmBgAndDesc/TitleSmBgAndDesc";
import AccessThousands from "../../components/WhyYouShouldJoin/components/AccessThousands";
import PersonalReviews from "../../components/WhyYouShouldJoin/components/PersonalReviews";
import TheBestGuidance from "../../components/WhyYouShouldJoin/components/TheBestGuidance";
import StudentsSaying from "../../components/Pricing/StudentsSaying";
import TitleList from "../../components/TemplatePages/TitleList/TitleList";
import BlockCTA from "../../components/TemplatePages/BlockCTA/BlockCTA";
import JoiningTG from "../../components/Homepage/JoiningTG";
import Fade from "react-reveal/Fade";

export const WebinarOptinTemplate = ({ frontmatter }) => {
  const distance = '30%';
  return (
    <React.Fragment>
      <TemplateHeader
        bgTitle={frontmatter.compellingHeadline.title}
        videoURL={frontmatter.compellingHeadline.videoURL}
      />
      <TitleSmBgAndDesc
        smTitle={frontmatter.secondBlock.smTitle}
        bgTitle={frontmatter.secondBlock.bgTitle}
        description={frontmatter.secondBlock.description}
        buttonLink={frontmatter.secondBlock.buttonLink}
      />
      <section className='container pb-7'>
        <hr className="divider d-md-none" />
        <Fade left distance={distance}>
          <AccessThousands
            title={frontmatter.accessThousands.title}
            slider={frontmatter.accessThousands.slider}
            description={frontmatter.accessThousands.description}
            buttonLink={frontmatter.accessThousands.buttonLink}
          />
        </Fade>
        <hr className="divider d-md-none" />
        <Fade right distance={distance}>
          <PersonalReviews
            title={frontmatter.personalReviews.title}
            videoURL={frontmatter.personalReviews.videoURL}
            description={frontmatter.personalReviews.description}
            buttonLink={frontmatter.personalReviews.buttonLink}
          />
        </Fade>
        <hr className="divider d-md-none" />
        <Fade left distance={distance}>
          <TheBestGuidance
            title={frontmatter.theBestGuidance.title}
            videoURL={frontmatter.theBestGuidance.videoURL}
            description={frontmatter.theBestGuidance.description}
            buttonLink={frontmatter.theBestGuidance.buttonLink}
          />
        </Fade>
        <Fade right distance={distance}>
          <StudentsSaying
            title={frontmatter.studentsSaying.title}
            studentsComments={frontmatter.studentsSaying.studentsComments}
          />
        </Fade>
        <Fade left distance={distance}>
          <TitleList
            bgTitle={frontmatter.whyJoin.bgTitle}
            smTitle={frontmatter.whyJoin.smTitle}
            list={frontmatter.whyJoin.items}
          />
        </Fade>
        <JoiningTG
          title={frontmatter.joiningTG.title}
          benefits={frontmatter.joiningTG.benefits}
        />
        <Fade left distance={distance}>
          <BlockCTA
            title={frontmatter.blockCTA.title}
            buttonLink={frontmatter.blockCTA.buttonLink}
          />
        </Fade>
      </section>
    </React.Fragment>
  );
};

WebinarOptinTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const WebinarOptin = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout  title={frontmatter.compellingHeadline.title || 'Info Page'}>
      <WebinarOptinTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

WebinarOptin.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WebinarOptin;

export const pageQuery = graphql`
  query WebinarOptin($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        compellingHeadline {
          title
          videoURL
        }
        secondBlock {
          smTitle
          bgTitle
          description
          buttonLink {
            label
            path
          }
        } 
        accessThousands {
          buttonLink{
            label,
            path
          }
          slider {
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          description
          title
        }
        personalReviews {
          buttonLink{
              label,
              path
          }
          description
          title
          videoURL
        }
        theBestGuidance {
          buttonLink{
              label,
              path
          }
          description
          title
          videoURL
        }
        studentsSaying {
          title
          studentsComments {
            name
            state
            comment
            videoURL
            photo {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        whyJoin {
          smTitle
          bgTitle
          items
        }
        joiningTG {
          title
          benefits {
            buttonLink{
                label,
                path
            }
            description
            title
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
        blockCTA {
          title
          buttonLink {
            label
            path
          }
        }
      }
    }
  }
`;
