import React from 'react';
import PropTypes from 'prop-types';
import './titleSmBgAndDesc.scss';

const TitleSmBgAndDesc = ({ smTitle, bgTitle, description, buttonLink }) => {
  return (
    <section className="position-relative titleSmBgAndDesc">
      <div className="container pt-7">
        <div className="row">
          <div className="col text-center">
            <h1 className="h1 text-primary">
              <span className="subtitle font-weight-bold text-primary">{smTitle}</span>
              <br />
              {bgTitle}
            </h1>
          </div>
        </div>
        {description && <p className="mb-4 text-center mt-7">{description}</p>}
        { buttonLink &&
          <div className="col col-12 mt-5 cta-button mb-8">
            <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a>
          </div>
        }
      </div>
    </section>
  );
};

TitleSmBgAndDesc.propTypes = {
  smTitle: PropTypes.string,
  bgTitle: PropTypes.string,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
};

export default TitleSmBgAndDesc;
