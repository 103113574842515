import React from 'react';
import PropTypes from 'prop-types';
import './blockCTA.scss';
import CtaButtonBlock from '../CtaButtonBlock';

const BlockCTA = ({
  title,
  buttonLink,
  isCaptureEmailForm = false,
  openEmailFormCallback = () => {}
}) => {
  return (
    <section className="position-relative blockCTA">
      <div className="container py-7">
        <div className="row justify-content-center">
          <div className="col col-12 text-center">
            <h1 className="h1 text-primary">
              {title}
            </h1>
          </div>

          {
            buttonLink && (
              <CtaButtonBlock
                label={buttonLink.label}
                path={buttonLink.path}
                isCaptureEmailForm={isCaptureEmailForm}
                openEmailFormCallback={openEmailFormCallback}
              />
            )
          }
        </div>
      </div>
    </section>
  );
};

BlockCTA.propTypes = {
  title: PropTypes.string,
  buttonLink: PropTypes.object,
  isCaptureEmailForm: PropTypes.bool,
};

export default BlockCTA;
