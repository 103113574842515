import React from 'react';
import PropTypes from 'prop-types';
import PreviewCompatibleImage from "../Common/PreviewCompatibleImage";
import MemoVideoPlayer from "../Common/VideoPlayer/VideoPlayer";

const StudentsSaying = ({title, studentsComments}) => {

  return (
    <section className="py-7 bg-primary-200">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <h2 className="text-primary text-center mb-6">{title} </h2>
          </div>
          {
            (studentsComments && studentsComments.length > 0) && (studentsComments.map((student, i) => (
              <section key={i} className="col col-12 col-md-6 col-lg-4 mb-3">
                <div className="card card-transparent">
                  {student && student.videoURL ?
                      <MemoVideoPlayer
                        video={{ url: student.videoURL, platform: 'vimeo' }}
                        autoplay={false}
                        hasControl={true}
                        loop={false}
                        title={false}
                        muted={false}
                      />
                    :
                      student && student.photo && (
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: student.photo,
                          alt: student.name,
                          className: 'card-img-top',
                        }}
                      />)
                  }
                  <div className="card-body text-center">
                    <p className="card-text">"{student.comment}"</p>
                    <h3 className="card-title text-uppercase text-primary mb-0">{student.name}, {student.state}</h3>
                  </div>
                </div>
              </section>
            )))
          }
        </div>
      </div>
    </section>
  )
};

StudentsSaying.propTypes = {
  title: PropTypes.string,
  studentsComments: PropTypes.array,
};

export default StudentsSaying;
